import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

import { useQuery } from '@apollo/client';
import { PageMetaData } from '@components/components/PageMetaData';
import { Loader } from '@core-frontend/components/Loader';
import { avaliableBlocks } from '@core-utils/avaliableBlocks';

import { Instagram as InstagramIcon } from '../../../public/images/icons/icons';
import { ContentfulPagesRenderer } from '../../renderers/ContentfulPagesRenderer';

import { notFoundPageQuery } from './NotFoundPageQuery';
import type { OwnProps } from './types';

import EditorialPagesStyles from '../EditorialPage/EditorialPage.module.scss';

export const NotFoundPage: React.FC<OwnProps> = () => {
  const { formatMessage } = useIntl();
  const notFoundVariables = {
    query: {
      fields: {
        slug: '404-page',
      },
    },
  };
  const {
    instagram,
    textintro,
    centraProducts,
    textbar,
    promo50,
    promo50small,
    spacer,
    featuredproduct,
    texteditor,
    hero,
    hero50,
    hero50textimage,
    panorama,
    panorama50,
    tripplepromo,
    tripplepromotext,
    textquote,
  } = avaliableBlocks;
  const { loading, error, data } = useQuery(notFoundPageQuery, { variables: notFoundVariables });

  const { data: galleriesData } = useQuery(notFoundPageQuery, {
    variables: notFoundVariables,
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p className="page-status error">{formatMessage({ id: 'error.sad' })};</p>;
  }

  const page = Array(data.cfPages) && data.cfPages[0];

  const params = {
    page,
    instagramIcon: InstagramIcon,
    className: EditorialPagesStyles,
    imageType: 'standard',
    noHoverAction: true,
    isSwiper: true,
    availableModules: [
      textbar,
      promo50,
      promo50small,
      spacer,
      hero,
      hero50,
      hero50textimage,
      panorama,
      panorama50,
      tripplepromotext,
      tripplepromo,
      promo50,
      promo50small,
      featuredproduct,
      texteditor,
      textintro,
      centraProducts,
      instagram,
      textquote,
    ],
  };

  return (
    <Fragment>
      <PageMetaData data={page} />
      <ContentfulPagesRenderer {...params} />
    </Fragment>
  );
};
