import { gql } from '@apollo/client';
import { CentraProductsFragment } from '@components/fragments/CentraProductsFragment';
import { FeaturedProductFragment } from '@components/fragments/FeaturedProductFragment';
import { Hero50Fragment } from '@components/fragments/Hero50Fragment';
import { Hero50TextImageFragment } from '@components/fragments/hero50TextImageFragment';
import { HeroFragment } from '@components/fragments/HeroFragment';
import { InstagramFragment } from '@components/fragments/InstagramFragment';
import { LinkFragment } from '@components/fragments/LinkFragment';
import { Panorama50Fragment } from '@components/fragments/Panorama50Fragment';
import { PanoramaFragment } from '@components/fragments/PanoramaFragment';
import { Promo50Fragment } from '@components/fragments/Promo50Fragment';
import { Promo50SmallFragment } from '@components/fragments/Promo50SmallFragment';
import { SpacerFragment } from '@components/fragments/SpacerFragment';
import { TextBarFragment } from '@components/fragments/TextBarFragment';
import { TextEditorFragment } from '@components/fragments/TextEditorFragment';
import { TextIntroFragment } from '@components/fragments/TextIntroFragment';
import { TextQuoteFragment } from '@components/fragments/TextQuoteFragment';
import { TripplePromoFragment } from '@components/fragments/TripplePromo';
import { TripplePromoTextFragment } from '@components/fragments/TripplePromoText';

export const notFoundPageQuery = gql`
  query notFoundPageQuery($query: EntryQueryType) {
    cfPages(q: $query) {
      title
      pageTitle
      metaTitle
      metaDescription
      slug
      transparentMenu
      menus {
        title
        tile
        name
        menu {
          ...LinkFragment
        }
      }
      galleries {
        ...SpacerFragment
        ...FeaturedProductFragment
        ...HeroFragment
        ...Hero50Fragment
        ...Hero50TextImageFragment
        ...PanoramaFragment
        ...Panorama50Fragment
        ...TripplePromoFragment
        ...TripplePromoTextFragment
        ...Promo50Fragment
        ...Promo50SmallFragment
        ...TextBarFragment
        ...CentraProductsFragment
        ...TextEditorFragment
        ...TextIntroFragment
        ...InstagramFragment
        ...TextQuoteFragment
      }
    }
  }
  ${SpacerFragment}
  ${FeaturedProductFragment}
  ${HeroFragment}
  ${Hero50Fragment}
  ${Hero50TextImageFragment}
  ${PanoramaFragment}
  ${Panorama50Fragment}
  ${TripplePromoFragment}
  ${TripplePromoTextFragment}
  ${Promo50Fragment}
  ${Promo50SmallFragment}
  ${TextBarFragment}
  ${CentraProductsFragment}
  ${TextEditorFragment}
  ${TextIntroFragment}
  ${TextQuoteFragment}
  ${InstagramFragment}
  ${LinkFragment}
`;
