import AppLayout from '@components/AppLayout';
import { NotFoundPage } from '@components/pages/NotFoundPage';

const Custom404 = () => {
  return (
    <AppLayout title="BLK DNM - 404">
      <NotFoundPage />
    </AppLayout>
  );
};

export default Custom404;
